import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-photoshow',
  templateUrl: './photoshow.component.html',
  styleUrls: ['./photoshow.component.css']
})
export class PhotoshowComponent implements OnInit {
@Input() data = [];

showFlag: boolean = false;
currentIndex: number = -1;

  constructor () {}
  showLightbox(index) {
      this.currentIndex = index;
      this.showFlag = true;
  }

  closeEventHandler() {
      this.showFlag = false;
      this.currentIndex = -1;
  }
  ngOnInit(): void {
  }

  
}
