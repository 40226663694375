<app-nav></app-nav>
<div class="content">
    <h4>{{'Trumpets' | translate}}</h4>
    <div class="row">
        <div class="col-md-8 left">
            <div class="row">
                <div class="col-md-4">
                    <img src="../../assets/imgs/trumpets/2.jpg" alt="">
                </div>
                <div class="col-md-8">
                    <p>{{'Trumpet-text' | translate}}</p>
                </div>
            </div>
            <hr>

            <div>
                <h5>{{'Options' | translate}}</h5>

                <h6> {{'Historical' | translate }}</h6>
                <ul>
                    <li>{{ 'Tpt-opt15' | translate }}</li>
                    <li>{{ 'Tpt-opt16' | translate }}</li>
                    <li>{{ 'Tpt-opt11' | translate }}</li>
                    <li>{{ 'Tpt-opt12' | translate }}</li>
                    <li>{{ 'Tpt-opt9' | translate }}</li>

                </ul>

                <h6> {{ '3System' | translate }}</h6>
                <ul>
                    <li>{{ 'Tpt-opt13' | translate }}</li>
                    <li>{{ 'Tpt-opt14' | translate }}</li>
                    <li>{{ 'Tpt-opt11' | translate }}</li>
                    <li>{{ 'Tpt-opt10' | translate }}</li>
                    <li>{{ 'Tpt-opt12' | translate }}</li>
                </ul>

                <h6> {{ '4System' | translate }}</h6>
                <ul>
                    <li>{{ 'Tpt-opt1' | translate }}</li>
                    <li>{{ 'Tpt-opt2' | translate }}</li>
                    <li>{{ 'Tpt-opt3' | translate }}</li>
                    <li>{{ 'Tpt-opt4' | translate }}</li>
                    <li>{{ 'Tpt-opt5' | translate }}</li>
                    <li>{{ 'Tpt-opt6' | translate }}</li>
                    <li>{{ 'Tpt-opt7' | translate }}</li>
                    <li>{{ 'Tpt-opt8' | translate }}</li>
                    <li>{{ 'Tpt-opt9' | translate }}</li>
                    <li>{{ 'Tpt-opt10' | translate }}</li>
                    <li>{{ 'Tpt-opt11' | translate }}</li>
                    <li>{{ 'Tpt-opt12' | translate }}</li>
                </ul>
                <a routerLink="/contact">
                    <p><em>{{'Contact-me' | translate }}</em></p>
                </a>
            </div>

        </div>
        <div class="col-md-4">
            <h5>{{'Natural-Trumpets' | translate}}</h5>
            <app-photoshow [data]="naturaltrumpets"></app-photoshow>

            <h5>{{'Anafil' | translate}}</h5>
            <app-photoshow [data]="anafil"></app-photoshow>

            <h5>{{'Slide-Trumpets' | translate }}</h5>
            <app-photoshow [data]="slidetrumpets"></app-photoshow>


        </div>
    </div>

</div>


<app-footer></app-footer>