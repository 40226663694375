import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BiogComponent } from './biog/biog.component';
import { ContactComponent } from './contact/contact.component';
import { NavComponent } from './nav/nav.component';
import { HeroComponent } from './hero/hero.component';
import { HomeComponent } from './home/home.component';
import { PhotoshowComponent } from './photoshow/photoshow.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { CarouselComponent } from './carousel/carousel.component';
import { FooterComponent } from './footer/footer.component';
import { SackbutsComponent } from './sackbuts/sackbuts.component';
import { TrumpetsComponent } from './trumpets/trumpets.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { AccessoriesComponent } from './accessories/accessories.component';

export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    BiogComponent,
    ContactComponent,
    NavComponent,
    HeroComponent,
    HomeComponent,
    PhotoshowComponent,
    CarouselComponent,
    FooterComponent,
    SackbutsComponent,
    TrumpetsComponent,
    AccessoriesComponent
  ],
  imports: [
    NgImageFullscreenViewModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ScullyLibModule
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
