import { Component, OnInit } from '@angular/core';
import {TENORSACKBUTS, ALTOSACKBUTS, BASSSACKBUTS } from '../../assets/photos';

@Component({
  selector: 'app-sackbuts',
  templateUrl: './sackbuts.component.html',
  styleUrls: ['./sackbuts.component.css']
})
export class SackbutsComponent implements OnInit {
  tenors = TENORSACKBUTS;
  basses = BASSSACKBUTS;
  altos = ALTOSACKBUTS;

  constructor() { }

  ngOnInit(): void {
  }

}
