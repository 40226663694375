<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a routerLink="" class="navbar-brand" >{{'SiteTitle' | translate }}
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item ">
          <a class="nav-link" routerLink="">Home <span class="sr-only"></span></a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" routerLink="/tony">Tony <span class="sr-only"></span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/sackbuts">{{'Sackbuts' | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/trumpets">{{'Trumpets' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/accessories">{{'Accessories' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contact">{{'Contact' | translate }}</a>
        </li>
    
      </ul>
   
  
    <div class="ml-auto">
      <a href="https://www.facebook.com/tony.esparismatanza"><i class="fab fa-facebook-f "></i></a>
    </div>
    <span class="form-inline">
      <select class="form-control" #selectedLang (change)="switchLang(selectedLang.value)">
        <option *ngFor="let language of translate.getLangs()" [value]="language"
          [selected]="language === translate.currentLang">
          {{ language }}
        </option>
      </select>
    </span>
    </div>
  </nav>