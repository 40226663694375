<app-nav></app-nav>

<div class="content">
    <div class="row">
        <div class="col-md-9 text-justify">
            <h4>Tony Esparis</h4>
            <p>{{"Biog-para1" | translate }}</p>
            <p>{{"Biog-para2" | translate }}</p>
        </div>
        <div class="col-md-3">
            <img src="../../assets/imgs/Tony 1.png" alt="">
        </div>
        <div class="col-md-3">
            <img src="../../assets/imgs/Tony 2.2.png" alt="">

        </div>
        <div class="col-md-9 text-justify">
            <p>{{"Biog-para3" | translate }}</p>
            <p>{{"Biog-para4" | translate }}</p>
            <div class="row">
                <div *ngFor="let photo of photos" class="col-md-3">
                    <div class="crop">
                        <img src="{{photo.image}}" alt="">
                    </div>
                </div>
            </div>
        </div>



    </div>





</div>



<app-footer></app-footer>