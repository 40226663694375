<app-nav></app-nav>
<div class="content">
    <h4>{{'Sackbuts' | translate}}</h4>
    <div class="row">
        <div class="col-md-4 left">
            <img src="../../assets/imgs/HomePhotos/1.jpg" alt="">
            <p>{{'Sackbut-text' | translate}}</p>
        </div>
        <div class="col-md-8">
            <h5>{{'Options' | translate}}</h5>
            <ul>
                <li>{{ 'Sackbut-opt1' | translate }}</li>
                <li>{{ 'Sackbut-opt2'| translate }}</li>
                <li>{{ 'Sackbut-opt2b'| translate }}</li>
                <li>{{ 'Sackbut-opt3' | translate }}</li>
                <li>{{ 'Sackbut-opt4' | translate }}</li>
                <li>{{ 'Sackbut-opt5' | translate }}</li>
                <li>{{ 'Sackbut-opt6' | translate }}</li>
                <li>{{ 'Sackbut-opt7' | translate }}</li>
                <li>{{ 'Sackbut-opt8' | translate }}</li>
                <li>{{ 'Sackbut-opt9' | translate }}</li>
                <li>{{ 'Sackbut-opt10' | translate }}</li>
            </ul>
            <a routerLink="/contact">
                <p><em>{{'Contact-me' | translate }}</em></p>
            </a>
        </div>
    </div>
    <hr>
    <div class="row">
<div class="col-md-4">
    <h5>{{'Alto-Sackbuts' | translate}}</h5>
    <p>{{'Alto-types' | translate}}</p>
    <app-photoshow [data]="altos" ></app-photoshow>
</div>
<div class="col-md-4">
    <h5>{{'Tenor-Sackbuts' | translate}}</h5>
    <p>{{'Tenor-types' | translate}}</p>

    <app-photoshow [data]="tenors" ></app-photoshow>

</div>
<div class="col-md-4">
    <h5>{{'Bass-Sackbuts' | translate }}</h5>
    <p>{{'Bass-types' | translate}}</p>

    <app-photoshow [data]="basses" ></app-photoshow>

</div>

    </div>

</div>
<app-footer></app-footer>