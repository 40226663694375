<app-nav></app-nav>
<div class="content">
    <div class="row">
        <div class="col-md-6 text-justify left">
            <h4>{{'Mouthpieces' | translate }}</h4>
            <p> {{'Mouthpieces-text' | translate }}</p>
            <a routerLink="/contact">
                <p><em>{{'Contact-me' | translate }}</em></p>
            </a>
            <img src="../../assets/imgs/Accessories/1.jpg" alt="">
            <app-photoshow [data]="mouthpieces" ></app-photoshow>
        </div>
        <div class="col-md-6 text-justify">
            <h4> {{'Cases' | translate }}</h4>
            <p>{{'Cases-text' | translate }}</p>
            <a routerLink="/contact">
                <p><em>{{'Contact-me' | translate }}</em></p>
            </a>
            <img src="../../assets/imgs/Accessories/estuches_modelos.jpg" alt="">
            <app-photoshow [data]="cases" ></app-photoshow>

        </div>
    </div>







</div>

<app-footer></app-footer>