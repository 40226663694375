import { Component, OnInit } from '@angular/core';
import { CASES, MOUTHPIECES } from '../../assets/photos';

@Component({
  selector: 'app-accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.css']
})
export class AccessoriesComponent implements OnInit {
cases = CASES;
mouthpieces = MOUTHPIECES;

  constructor() { }

  ngOnInit(): void {
  }

}
