import { Component, OnInit } from '@angular/core';
import {SLIDETRUMPETS, ANAFIL, NATURALTRUMPETS, TRUMPETS} from '../../assets/photos';

@Component({
  selector: 'app-trumpets',
  templateUrl: './trumpets.component.html',
  styleUrls: ['./trumpets.component.css']
})
export class TrumpetsComponent implements OnInit {
anafil = ANAFIL;
slidetrumpets = SLIDETRUMPETS;
naturaltrumpets = NATURALTRUMPETS;
trumpets = TRUMPETS;

  constructor() { }

  ngOnInit(): void {
  }

}
