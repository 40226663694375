import { Component, OnInit } from '@angular/core';
import { WORKSHOP } from '../../assets/photos';

@Component({
  selector: 'app-biog',
  templateUrl: './biog.component.html',
  styleUrls: ['./biog.component.css']
})
export class BiogComponent implements OnInit {
photos = WORKSHOP;

  constructor() { }

  ngOnInit(): void {
  }

}
