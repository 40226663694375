<app-nav></app-nav>
<div id="hero">

</div>
<div class="content text-center">
    <img id="logo" src="../../assets/imgs/vieira.png" alt="">
    <h4>{{ "Contact" | translate }}</h4>
    <p>{{ "Contact-details" | translate }}</p>
<div class="row icons">
<div class="col-md-4">
   <a href="mailto:tonyesparis@gmail.com"> <i class="far fa-envelope fa-5x"></i></a>
   <p><a href="mailto:tonyesparis@gmail.com">tonyesparis@gmail.com</a></p>
</div>
<div class="col-md-4">
    <a href="https://api.whatsapp.com/send?phone=34722508041"><i id="whatsapp" class="fab fa-whatsapp-square fa-5x"></i></a>
    <p>WhatsApp</p>
</div>
<div class="col-md-4">
   <a href="tel:34722508041"> <i class="fas fa-phone fa-5x"></i></a>
    <p>{{'Telephone' | translate}} +34 722 508 041</p>
</div>

</div>


</div>

<app-footer></app-footer>