export const HOME = [
    {title:'', image:'../assets/imgs/HomePhotos/1.jpg'},
    {title:'', image:'../assets/imgs/HomePhotos/2.jpg'},
    {title:'', image:'../assets/imgs/HomePhotos/3.jpg'},
    {title:'', image:'../assets/imgs/HomePhotos/4.jpg'},
    {title:'', image:'../assets/imgs/HomePhotos/5.jpg'},
    {title:'', image:'../assets/imgs/HomePhotos/6.jpg'},
    {title:'', image:'../assets/imgs/HomePhotos/7.jpg'},
    {title:'', image:'../assets/imgs/HomePhotos/8.jpg'},
    {title:'', image:'../assets/imgs/HomePhotos/9.jpg'},
    {title:'', image:'../assets/imgs/HomePhotos/10.jpg'},

];

export const SACKBUTS = [
    {title:'', image:'../assets/imgs/sackbuts/1.jpg'},
    {title:'', image:'../assets/imgs/sackbuts/2.jpg'},
    {title:'', image:'../assets/imgs/sackbuts/3.jpg'},
    {title:'', image:'../assets/imgs/sackbuts/4.jpg'},
    {title:'', image:'../assets/imgs/sackbuts/5.jpg'},
]

export const ALTOSACKBUTS = [
    {title:'Baroque Alto with Classical Bell', image:'../assets/imgs/sackbuts/allsackbuts/Alto.jpg'},
    {title:'Classical Alto Bell', image:'../assets/imgs/sackbuts/allsackbuts/Alto2.jpg'},
    {title:'Classical Alto Bell-stay', image:'../assets/imgs/sackbuts/allsackbuts/Alto3.jpg'},
    {title:'Baroque Alto after Paul Hainlein', image:'../assets/imgs/sackbuts/allsackbuts/Alto4.jpg'},
];

export const TENORSACKBUTS = [
    {title: 'Tenor Sackbut - Drewelwecz Model', image:'../assets/imgs/sackbuts/allsackbuts/Drewelwecz.jpg'},
    {title: 'Tenor Sackbut - Drewelwecz Model', image:'../assets/imgs/sackbuts/allsackbuts/DrewDetail1.jpg'},
    {title: 'Tenor Sackbut - Drewelwecz Model', image:'../assets/imgs/sackbuts/allsackbuts/DrewDetail2.jpg'},
    {title: 'Tenor Sackbut - Drewelwecz Model', image:'../assets/imgs/sackbuts/allsackbuts/DrewDetail3.jpg'},
    {title: 'Tenor Sackbut - Drewelwecz Model', image:'../assets/imgs/sackbuts/allsackbuts/DrewDetail4.jpg'},
    {title: 'Tenor Sackbut - Slide Options', image:'../assets/imgs/sackbuts/allsackbuts/DrewSlides.jpg'},
    {title: 'Tenor Sackbut - Water-Key', image:'../assets/imgs/sackbuts/allsackbuts/TenorWaterKey.jpg'},
    {title: 'Tenor Sackbut - Tenor Sackbut Schnitzer Model', image:'../assets/imgs/sackbuts/allsackbuts/Schnitzer1.jpg'},
    {title: 'Tenor Sackbut - Schnitzer Model', image:'../assets/imgs/sackbuts/allsackbuts/Schnitzer2.jpg'},
    {title: 'Tenor Sackbut - Schnitzer Model', image:'../assets/imgs/sackbuts/allsackbuts/Schnitzer3.jpg'},
    {title: 'Tenor Sackbut - Schnitzer Model', image:'../assets/imgs/sackbuts/allsackbuts/Schnitzer4.jpg'},
    {title: 'Tenor Sackbut - Schnitzer Model', image:'../assets/imgs/sackbuts/allsackbuts/SchnitzerDetail.jpg'},
];

export const BASSSACKBUTS = [
    {title:'Classical Bass Bell', image:'../assets/imgs/sackbuts/allsackbuts/BassClassicalBell.jpg'},
    {title:'Classical Bass Bell Decoration', image:'../assets/imgs/sackbuts/allsackbuts/BassDetailClassical.jpg'},
    {title:'Bass Sackbut Öller Model', image:'../assets/imgs/sackbuts/allsackbuts/oller.jpg'},
    {title:'Bass Sackbut Öller Model', image:'../assets/imgs/sackbuts/allsackbuts/oller1.jpg'},
    {title:'Bass Sackbut Öller Model', image:'../assets/imgs/sackbuts/allsackbuts/Bass_bomba_oller.jpg'},
    {title:'Bass Sackbut Öller Model', image:'../assets/imgs/sackbuts/allsackbuts/Bass_bomba.jpg'},
    {title:'Bass Sackbut Öller Model', image:'../assets/imgs/sackbuts/allsackbuts/Bass_bomba465.jpg'},
    {title:'Bass Sackbut Öller Model', image:'../assets/imgs/sackbuts/allsackbuts/Bass_detalle_decoracion1.jpg'},
    {title:'Bass Sackbut Öller Model', image:'../assets/imgs/sackbuts/allsackbuts/Bass_detalle_decoracion2.jpg'},
    {title:'Bass Sackbut Öller Model', image:'../assets/imgs/sackbuts/allsackbuts/Bass_detalle.jpg'},
    {title:'Bass Sackbut Water Key', image:'../assets/imgs/sackbuts/allsackbuts/BassWaterKey.jpg'},
    {title:'Bass Sackbut Öller Model without tuning slide', image:'../assets/imgs/sackbuts/allsackbuts/oller_sin_bomba.jpg'},
];

export const TRUMPETS =[
    {title: 'Añafil Set', image:'../assets/imgs/trumpets/alltrumpets/anafil2.jpg'},
    {title:'Trompeta Ministriles', image:'../assets/imgs/trumpets/alltrumpets/trompetaministriles.jpg'},
    {title:'Renaissance Trumpet', image:'../assets/imgs/trumpets/alltrumpets/trompetarenacentista2.jpg'},
]

export const ANAFIL = [
    {title: 'Añafil Set', image:'../assets/imgs/trumpets/alltrumpets/anafil.jpg'},
    {title: 'Añafil Set', image:'../assets/imgs/trumpets/alltrumpets/anafil2.jpg'},
    {title: 'Añafil Detail', image:'../assets/imgs/trumpets/alltrumpets/anafildetalle1.jpg'},
    {title: 'A˜ãfil Detail', image:'../assets/imgs/trumpets/alltrumpets/anafildetalle2.jpg'},
];

export const NATURALTRUMPETS = [
    {title:'Baroque Trumpet', image:'../assets/imgs/trumpets/alltrumpets/baroquetrumpet.jpg'},
    {title:'Renaissance Trumpet', image:'../assets/imgs/trumpets/alltrumpets/trompetarenacentista.jpg'},
    {title:'Renaissance Trumpet', image:'../assets/imgs/trumpets/alltrumpets/trompetarenacentista1.jpg'},
    {title:'Renaissance Trumpet', image:'../assets/imgs/trumpets/alltrumpets/trompetarenacentista2.jpg'},
    {title:'Trumpet Detail', image:'../assets/imgs/trumpets/alltrumpets/trompetadetalle.jpg'},
];

export const SLIDETRUMPETS = [
    {title:'Trompeta Ministriles', image:'../assets/imgs/trumpets/alltrumpets/trompetaministriles.jpg'},
    {title:'Slide Trumpet', image:'../assets/imgs/trumpets/alltrumpets/slidetrumpet.jpg'},
    {title:'Mouthpiece', image:'../assets/imgs/trumpets/alltrumpets/boquillaSXV.jpg'},
    {title:'Detail from Memling\'s Painting', image:'../assets/imgs/trumpets/alltrumpets/cuadro.jpg'},
    {title:'Slide Trumpet Detail', image:'../assets/imgs/trumpets/alltrumpets/slidetrumpetdetalle.jpg'},
    {title:'Slide Trumpet Detail', image:'../assets/imgs/trumpets/alltrumpets/slidetrumpetdetalle1.jpg'},
];

export const WORKSHOP = [
    {title:'', image:'../assets/imgs/workshop/1.jpg'},
    {title:'', image:'../assets/imgs/workshop/2.jpg'},
    {title:'', image:'../assets/imgs/workshop/3.jpg'},
    {title:'', image:'../assets/imgs/workshop/4.jpg'},
    {title:'', image:'../assets/imgs/workshop/5.jpg'},
    {title:'', image:'../assets/imgs/workshop/6.jpg'},
    {title:'', image:'../assets/imgs/workshop/7.jpg'},
    {title:'', image:'../assets/imgs/workshop/8.jpg'},
];

export const MOUTHPIECES = [
{title:'', image:'../assets/imgs/Accessories/1.jpg'},
{title:'', image:'../assets/imgs/Accessories/2.jpg'},
{title:'', image:'../assets/imgs/Accessories/schnitzer.jpg'},
{title:'', image:'../assets/imgs/Accessories/slidetrumpetdetalle.jpg'},
{title:'', image:'../assets/imgs/Accessories/boquillaSXV.jpg'},
{title:'', image:'../assets/imgs/Accessories/anafildetalle2.jpg'},
];

export const CASES =[
{title:'', image:'../assets/imgs/Accessories/estuche_rigido.jpg'},
{title:'', image:'../assets/imgs/Accessories/estuche_rigido1.jpg'},
{title:'', image:'../assets/imgs/Accessories/estuche_rigido2.jpg'},
{title:'', image:'../assets/imgs/Accessories/estuches_modelos.jpg'},
];
  