import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  constructor(
    public translate: TranslateService
  ){
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');
  }
  
  switchLang(lang: string){
  this.translate.use(lang);
  }

  ngOnInit(): void {
  }

}
