<app-hero></app-hero>
<app-nav></app-nav>
<div class="content">
    <div class="row">
        <div class="col-md-4">
            <a routerLink="/sackbuts">
                <div class="main-box">
                    <img src="../../assets/imgs/HomePhotos/1.jpg" alt="">
                    <div class="overlay">
                        <h6 class="title">{{'Sackbuts' | translate }}</h6>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-md-4">
            <p><em>{{'Home' | translate }}</em></p>

        </div>
        <div class="col-md-4 ">
            <a routerLink="/trumpets">
                <div class="main-box">
                    <img src="../../assets/imgs/trumpets/2.jpg" alt="">
                    <div class="overlay">
                        <h6 class="title">{{'Trumpets' | translate }}</h6>
                    </div>
                </div>
            </a>
        </div>
        <hr>
        <div class="text-center">
        <app-photoshow [data]="photos"></app-photoshow>
    </div>

    </div>


</div>

<app-footer></app-footer>