import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SackbutsComponent } from './sackbuts/sackbuts.component';
import { TrumpetsComponent } from './trumpets/trumpets.component';
import { ContactComponent } from './contact/contact.component';
import { BiogComponent } from './biog/biog.component'
import { AccessoriesComponent } from './accessories/accessories.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'sackbuts', component: SackbutsComponent},
  {path: 'trumpets', component: TrumpetsComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'tony', component: BiogComponent},
  {path: 'accessories', component: AccessoriesComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
